import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './UseCaseExplorer.module.scss';
import { Section } from './Page';
import Grid from './layout/Grid';
import Link from './Link';
import Text from './Text';
import { Theme } from '@material-ui/core';
import { useIsInViewport } from '../hooks/utilHooks';
import SectionHeading from './SectionHeading';

interface UseCase {
  id: string;
  name: React.ReactNode;
  innerTextBlock: React.ReactNode;
  media: React.ReactNode;
  durationSec: number;
}

type Props = {
  title: string | string[];
  useCases: UseCase[];
  classes?: {
    allUseCases?: string;
    card?: string;
  };
  renderCardBackground?: () => JSX.Element;
  onVisibilityChange?(isVisible: boolean): void;
  onActiveUseCaseChange?(id: string, prevId?: string): void;
  renderImage: () => JSX.Element;
};

const UseCaseExplorer = (props: Props) => {
  const [activeUseCaseIndex, setActiveUseCaseIndex] = useState<number | undefined>(
    props.useCases.length > 0 ? 0 : undefined
  );
  const [autoPlayEnabled, setAutoPlayEnabled] = useState(false);
  const [hiddenUseCasesByIndex, setHiddenUseCasesByIndex] = useState(
    props.useCases.reduce((result: Record<number, boolean>, useCase, idx) => {
      result[idx] = idx !== activeUseCaseIndex;
      return result;
    }, {})
  );
  const useCaseBtnsRef = useRef<HTMLDivElement>(null);
  const btnsSeen = useIsInViewport(useCaseBtnsRef, true);

  // Auto play through use cases if enabled
  useEffect(() => {
    if (activeUseCaseIndex === undefined || !autoPlayEnabled) {
      return;
    }

    const delayAfterAnim = 300;
    const timeoutId = setTimeout(() => {
      const nextIndex = activeUseCaseIndex + 1 < props.useCases.length ? activeUseCaseIndex + 1 : 0;

      changeActiveUseCase(nextIndex, activeUseCaseIndex);
    }, props.useCases[activeUseCaseIndex].durationSec * 1000 + delayAfterAnim);

    return () => clearTimeout(timeoutId);
  }, [props.useCases.length, activeUseCaseIndex, autoPlayEnabled]);

  // Set autoplay true if btns have been seen
  useEffect(() => {
    if (btnsSeen) {
      if (props.onVisibilityChange) {
        props.onVisibilityChange(true);
      }

      setAutoPlayEnabled(true);
    }
  }, [btnsSeen]);

  const onUseCaseClick = (index: number) => {
    changeActiveUseCase(index, activeUseCaseIndex);
    setAutoPlayEnabled(false);
  };

  function changeActiveUseCase(nextIndex: number, currIndex?: number) {
    if (props.onActiveUseCaseChange) {
      props.onActiveUseCaseChange(
        props.useCases[nextIndex].id,
        currIndex !== undefined ? props.useCases[currIndex].id : undefined
      );
    }

    setActiveUseCaseIndex(nextIndex);
    setHiddenUseCasesByIndex((state) => ({
      ...state,
      [nextIndex]: false,
    }));
  }

  function onUseCaseAnimationEnd(useCaseIndex: number) {
    if (useCaseIndex !== activeUseCaseIndex) {
      setHiddenUseCasesByIndex((state) => ({
        ...state,
        [useCaseIndex]: true,
      }));
    }
  }

  return (
    <Section className={styles.section} padding="sm" containerProps={{ className: styles.container }}>
      <SectionHeading title={props.title} renderImage={props.renderImage} />
      <div className={classNames(styles.card, props.classes?.card)}>
        <div ref={useCaseBtnsRef}>
          <Grid container spacing={2} wrap="nowrap" className={styles.useCasesContainer}>
            {props.useCases.map((useCase, idx) => {
              const isActive = idx === activeUseCaseIndex;

              return (
                <Grid item xs="flex" key={useCase.id}>
                  <button
                    className={isActive ? styles.useCaseBtnActive : styles.useCaseBtn}
                    onClick={() => onUseCaseClick(idx)}
                  >
                    <div className={styles.useCaseBtnInner}>
                      <div className={styles.useCaseTitleWrapper}>
                        <h3 className={styles.useCaseTitle}>{useCase.name}</h3>
                      </div>
                      <div
                        className={isActive && autoPlayEnabled ? styles.progressBarActive : styles.progressBar}
                        style={{ animationDuration: `${useCase.durationSec}s` }}
                      />
                    </div>
                  </button>
                </Grid>
              );
            })}
          </Grid>
        </div>
        <div className={classNames(styles.allUseCases, props.classes?.allUseCases)}>
          {props.useCases.map((useCase, idx) => (
            <div
              key={useCase.id}
              className={classNames({
                [styles.useCaseWrapper]: idx !== activeUseCaseIndex,
                [styles.useCaseWrapperActive]: idx === activeUseCaseIndex,
              })}
              style={{ display: hiddenUseCasesByIndex[idx] ? 'none' : 'block' }}
              onAnimationEnd={() => onUseCaseAnimationEnd(idx)}
            >
              <Grid container alignItems="center" wrap="wrap-reverse" justify="flex-start">
                <Grid item xs={12} md="auto" className={styles.useCaseContentItemAuto}>
                  {useCase.innerTextBlock}
                </Grid>
                <Grid item xs={12} md="flex" className={styles.useCaseContentItemFlex}>
                  <div className={styles.mediaContainer}>{useCase.media}</div>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
        {props.renderCardBackground?.()}
      </div>
    </Section>
  );
};

export const InnerTitle = (props: React.PropsWithChildren<{ className?: string }>) => (
  <Text
    el="h4"
    variant="heading2xs"
    className={props.className}
    css={(theme: Theme) => ({
      marginBottom: '1.6rem',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '1.2rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '1.4rem',
      },
    })}
  >
    {props.children}
  </Text>
);

export const Description = (props: React.PropsWithChildren<{ className?: string }>) => (
  <Text
    el="p"
    variant="paragraphMd"
    className={props.className}
    css={(theme: Theme) => ({
      marginBottom: '2.8rem',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '1.6rem',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '3.2rem',
      },
    })}
  >
    {props.children}
  </Text>
);

export const InnerLink = (props: React.ComponentProps<typeof Link>) => (
  <Link variant="btnSm" {...props}>
    {props.children}
  </Link>
);

export const customBreakpointWidth = styles.customMdSmBreakpoint;

UseCaseExplorer.InnerTitle = InnerTitle;
UseCaseExplorer.Desc = Description;
UseCaseExplorer.Link = InnerLink;

export default UseCaseExplorer;
