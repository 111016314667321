import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './Image.module.scss';

export type Props = React.ComponentProps<'img'> & {
  // Always provide width and height to images https://web.dev/optimize-cls/?utm_source=lighthouse&utm_medium=devtools#images-without-dimensions
  // This should be the natural dimensions of the image, or the expected dimensions defined in CSS, it will resize when the CSS is applied
  width: number | string;
  height: number | string;
  alt: string;
  disableFadeIn?: boolean; // disable fade in on load. This allows part of the image to show while loading
};

const Image = (props: Props) => {
  const imgEl = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!props.disableFadeIn && (imgEl.current?.complete || imgEl.current?.naturalWidth)) {
      setLoaded(true);
    }
  }, [imgEl.current]);

  const handleLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (!props.disableFadeIn) {
      setLoaded(true);
    }

    if (props.onLoad) {
      props.onLoad(e);
    }
  };

  const handleErr = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (!props.disableFadeIn) {
      setLoaded(true);
    }

    if (props.onError) {
      props.onError(e);
    }
  };

  const { disableFadeIn, ...otherProps } = props;

  return (
    <img
      {...otherProps}
      className={classnames(props.className, styles.image, {
        [styles.loading]: !loaded && !disableFadeIn,
        [styles.loaded]: loaded || disableFadeIn,
      })}
      onLoad={handleLoad}
      onError={handleErr}
      ref={disableFadeIn ? props.ref : imgEl}
    />
  );
};

export default Image;
