import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { css } from '@emotion/react';
import loadable from '@loadable/component';

import header from './media/header/header.png';
import header2x from './media/header/header2x.png';
import header3x from './media/header/header3x.png';
import CurvedUnderline from '../../media/curved-underline.svg';
import GooseCarryingPictures from '../../media/geese/goose-carrying-submissions.svg';
import Footprint from '../../media/goose-footprint.svg';
import FootprintPatterned from '../../media/goose-footprint-patterned.svg';

import activityFeed from './media/useCaseExplorer/activity-feed.png';
import activityFeed2x from './media/useCaseExplorer/activity-feed2x.png';
import activityFeed3x from './media/useCaseExplorer/activity-feed3x.png';
import missions from './media/useCaseExplorer/missions.png';
import missions2x from './media/useCaseExplorer/missions2x.png';
import missions3x from './media/useCaseExplorer/missions3x.png';
import onboardingDashboard from './media/useCaseExplorer/onboarding-dashboard.png';
import onboardingDashboard2x from './media/useCaseExplorer/onboarding-dashboard2x.png';
import onboardingDashboard3x from './media/useCaseExplorer/onboarding-dashboard3x.png';
import gameScript from './media/useCaseExplorer/script.png';
import gameScript2x from './media/useCaseExplorer/script2x.png';
import gameScript3x from './media/useCaseExplorer/script3x.png';
import teamBuilding from './media/useCaseExplorer/team-building.png';
import teamBuilding2x from './media/useCaseExplorer/team-building2x.png';
import teamBuilding3x from './media/useCaseExplorer/team-building3x.png';
import BackgroundTexture from './media/useCaseExplorer/bg-texture.svg';
import feetOutlineBg from './media/feet-outline.png';
import feetOutlineBgMobile from './media/feet-outline-mobile.png';
import RunningGoose from '../../media/geese/running-goose.svg';

import styles from './Home.module.scss';
import Page, { Section } from '../../components/Page';
import Image from '../../components/Image';
import CallToAction from '../../components/CallToAction';
import Grid from '../../components/layout/Grid';
import appConstants from '../../appConstants';
import routes from '../../routes';
import {
  InnerTitle as UseCaseInnerTitle,
  Description as UseCaseDesc,
  InnerLink as UseCaseLink,
  customBreakpointWidth as useCaseExplorerCustomBreakpoint,
} from '../../components/UseCaseExplorer';
import theme from '../../theme';
import { Theme } from '@material-ui/core';

// Lazy load below-the-fold content
const QuickToSetUpSection = loadable(() => import('./components/QuickToSetUpSection'));
const UseCaseExplorer = loadable(() => import('../../components/UseCaseExplorer'));
const MainFeaturesSection = loadable(() => import('./components/MainFeaturesSection'));
const HotRightNowSection = loadable(() => import('./components/HotRightNowSection'));
const TryItNowCTA = loadable(() => import('../../components/TryItNowCTA'));

const headingUseCases = [
  'students',
  'recreation',
  'remote teams',
  'team building',
  'fundraising',
  'self-guided tours',
  'youth groups',
  'onboarding',
  'orientations',
  'conferences',
  'group wellness',
  'festivals',
  'celebrations',
  'online contests',
  'icebreakers',
  'urban races',
];
const headingUseCaseIntervalMs = 3000;

const cycleIndex = (arr: Array<any>, index: number) => (index >= arr.length - 1 ? 0 : index + 1);
export const getRandomUseCaseStartingIndex = () => Math.round(Math.random() * (headingUseCases.length - 1));

type HeadingUseCaseData = {
  currIndex: number;
  prevIndex?: number;
};

const VIRTUAL_USE_CASE_ID = 'virtual';
const RECREATION_USE_CASE_ID = 'rec';
const ONBOARDING_USE_CASE_ID = 'onboarding';
const CAMPUS_USE_CASE_ID = 'campus';
const K12_USE_CASE_ID = 'k12';
type UseCase =
  | typeof VIRTUAL_USE_CASE_ID
  | typeof RECREATION_USE_CASE_ID
  | typeof ONBOARDING_USE_CASE_ID
  | typeof CAMPUS_USE_CASE_ID
  | typeof K12_USE_CASE_ID;

const useCaseInnerTitleCss = css`
  @media (min-width: ${useCaseExplorerCustomBreakpoint}) {
    max-width: 26.4rem;
  }

  ${theme.breakpoints.up('lg')} {
    max-width: 28rem;
  }
`;

const Home = () => {
  const [useCaseExplorerSeen, setUseCaseExplorerSeen] = useState(false);
  // Get the initial value stored in constants so it's the same as what the server rendered
  const [useCaseData, setUseCaseData] = useState<HeadingUseCaseData>({
    currIndex: appConstants.HOME_USE_CASE_INITIAL_INDEX,
  });

  // Cycle through heading use cases after first render
  useEffect(() => {
    if (headingUseCases.length <= 1) {
      return;
    }

    const intervalId = setInterval(() => {
      setUseCaseData((prevData) => ({
        currIndex: cycleIndex(headingUseCases, prevData.currIndex),
        prevIndex: prevData.currIndex,
      }));
    }, headingUseCaseIntervalMs);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Page
      title="The Original Interactive Experiences App | Goosechase"
      description="Inspired by scavenger hunts, Goosechase is an online platform that helps you run real-world interactive experiences for your communities. Try it today for free!"
    >
      <Section className={styles.headerSection}>
        <Grid container spacing={0}>
          <Grid item xs={12} md="auto">
            <div className={styles.headingTextContainer}>
              <h1 className={styles.h1}>
                <span>Create delightful experiences for</span>
                <br />
                <div className={styles.useCasesBlock}>
                  {headingUseCases.map((useCase, idx) => (
                    <div
                      key={idx}
                      className={classNames(styles.useCaseText, {
                        [styles.useCaseInitial]: useCaseData.currIndex === idx && useCaseData.prevIndex === undefined,
                        [styles.useCaseCurr]: useCaseData.currIndex === idx && useCaseData.prevIndex !== undefined,
                        [styles.useCasePrev]: useCaseData.prevIndex === idx,
                      })}
                    >
                      {useCase}
                    </div>
                  ))}
                </div>
              </h1>
              <CurvedUnderline />
              <p className={styles.subtitle}>
                Inspired by scavenger hunts, Goosechase is an online platform that helps you create interactive
                experiences.
              </p>
              <CallToAction />
            </div>
          </Grid>
          <Grid item xs={12} md="flex" className={styles.headerImgGridItem}>
            <div className={styles.headerImgWrapper}>
              <Image
                src={header}
                srcSet={`${header2x} 2x, ${header3x} 3x`}
                width="579"
                height="690"
                alt="Photo Submissions"
              />
            </div>
          </Grid>
        </Grid>
        <FootprintPatterned className={styles.leftFootprint} />
        <Footprint className={styles.centerFootprint} />
        <GooseCarryingPictures className={styles.headerGoose} />
      </Section>

      <QuickToSetUpSection />

      <UseCaseExplorer
        title={['How people use', 'Goosechase']}
        renderImage={() => <RunningGoose width={312} />}
        renderCardBackground={() => <BackgroundTexture className={styles.useCaseExplorerBackground} />}
        onVisibilityChange={(isVisible: boolean) => {
          if (isVisible && !useCaseExplorerSeen) {
            setUseCaseExplorerSeen(true);
          }
        }}
        useCases={[
          {
            id: RECREATION_USE_CASE_ID,
            name: 'Recreation',
            innerTextBlock: (
              <div className={styles.explorerUseCaseInnerText}>
                <UseCaseInnerTitle css={useCaseInnerTitleCss}>
                  One-of-a-kind experiences to easily activate communities
                </UseCaseInnerTitle>
                <UseCaseDesc>
                  Integrate Goosechase into your next festival, fundraiser, or community event to help raise awareness
                  and have fun for participants of all ages.
                </UseCaseDesc>
                <UseCaseLink href={routes.RECREATION_USES.path} useRouter>
                  Learn more
                </UseCaseLink>
              </div>
            ),
            media: (
              <Image
                src={gameScript}
                srcSet={`${gameScript2x} 2x, ${gameScript3x} 3x`}
                width="688"
                height="420"
                alt="Game script"
              />
            ),
            durationSec: 16,
          },
          {
            id: ONBOARDING_USE_CASE_ID,
            name: 'Onboarding',
            innerTextBlock: (
              <div className={styles.explorerUseCaseInnerText}>
                <UseCaseInnerTitle css={useCaseInnerTitleCss}>
                  Engage, excite and prepare new employees
                </UseCaseInnerTitle>
                <UseCaseDesc>
                  Set up your new hires up for success with an easy-to-organize and interactive program that allows you
                  to track and report engagement.
                </UseCaseDesc>
                <UseCaseLink href={routes.ONBOARDING_USES.path} useRouter>
                  Learn more
                </UseCaseLink>
              </div>
            ),
            media: (
              <Image
                src={onboardingDashboard}
                srcSet={`${onboardingDashboard2x} 2x, ${onboardingDashboard3x} 3x`}
                width="688"
                height="420"
                alt="Onboarding dashboard"
              />
            ),
            durationSec: 16,
          },
          {
            id: CAMPUS_USE_CASE_ID,
            name: 'Campus orientation',
            innerTextBlock: (
              <div className={styles.explorerUseCaseInnerText}>
                <UseCaseInnerTitle css={useCaseInnerTitleCss}>
                  Build a tight-knit campus community from day one
                </UseCaseInnerTitle>
                <UseCaseDesc>
                  Develop school spirit and create an experience both students and staff will want to write home about
                  by organizing creative, interactive, and fun events.
                </UseCaseDesc>
                <UseCaseLink href={routes.CAMPUS_ORIENTATION_USES.path} useRouter>
                  Learn more
                </UseCaseLink>
              </div>
            ),
            media: (
              <Image
                src={activityFeed}
                srcSet={`${activityFeed2x} 2x, ${activityFeed3x} 3x`}
                width="688"
                height="420"
                alt="Activity Feed"
              />
            ),
            durationSec: 16,
          },

          {
            id: K12_USE_CASE_ID,
            name: (
              <>
                K-12
                <br />
                education
              </>
            ),
            innerTextBlock: (
              <div className={styles.explorerUseCaseInnerText}>
                <UseCaseInnerTitle css={useCaseInnerTitleCss}>
                  Make learning fun, both in and out of the classroom
                </UseCaseInnerTitle>
                <UseCaseDesc>
                  More than just a scavenger hunt app, engage students of any age and boost learning with easy-to-run,
                  reusable games and activities that take education beyond the classroom.
                </UseCaseDesc>
                <UseCaseLink href={routes.K12_EDUCATION_USES.path} useRouter>
                  Learn more
                </UseCaseLink>
              </div>
            ),
            media: (
              <Image
                src={missions}
                srcSet={`${missions2x} 2x, ${missions3x} 3x`}
                width="688"
                height="420"
                alt="Missions"
              />
            ),
            durationSec: 16,
          },
          {
            id: VIRTUAL_USE_CASE_ID,
            name: 'Team building',
            innerTextBlock: (
              <div className={styles.explorerUseCaseInnerText}>
                <UseCaseInnerTitle css={useCaseInnerTitleCss}>
                  Fun for the whole virtual, hybrid or in-person team.
                </UseCaseInnerTitle>
                <UseCaseDesc>
                  Connect your team, engage any audience, or broaden your event&#39;s participant pool with a shared
                  interactive experience accessible from anywhere.
                </UseCaseDesc>
                <UseCaseLink href={routes.VIRTUAL_TEAMS_USES.path} useRouter>
                  Learn more
                </UseCaseLink>
              </div>
            ),

            media: (
              <Image
                src={teamBuilding}
                srcSet={`${teamBuilding2x} 2x, ${teamBuilding3x} 3x`}
                width="688"
                height="420"
                alt="Team building"
              />
            ),
            durationSec: 16,
          },
        ]}
      />

      <MainFeaturesSection />

      <HotRightNowSection />

      <Section
        padding="sm"
        css={(theme: Theme) => ({
          backgroundImage: `url(${feetOutlineBgMobile})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${feetOutlineBg})`,
          },
        })}
      >
        <TryItNowCTA
          desc={
            <>
              What&#39;s better than a demo? Access to the full platform! Build and run a game today. Create as many
              missions as you can muster, invite up to 3 teams, and see how easy Goosechase is to use, by easily using
              it.
            </>
          }
        />
      </Section>
    </Page>
  );
};

export default Home;
