import React from 'react';
import Image from './Image';
import Text from './Text';
import Box from './layout/Box';
import styles from './SectionHeading.module.scss';

interface Props {
  title: string | string[];
  renderImage?: () => JSX.Element;
}

const SectionHeading = ({ title, renderImage }: Props) => {
  const titlesToRender = Array.isArray(title) ? title : [title];
  return (
    <Box mb={{ xs: '3.2rem', sm: '4.4rem', lg: '5.2rem' }} textAlign="center">
      {renderImage?.()}
      <Text el="h2" variant="headingMd" className={styles.headingText} align="center">
        {titlesToRender.map((line) => (
          <span>
            {line}
            <br />
          </span>
        ))}
      </Text>
    </Box>
  );
};

export default SectionHeading;
